/* Right hand navigation overlay containing site map and global links */

@import "_variables";

@import "_fontcustom-dcs";

@font-face {
	font-family: "fontcustom-dcs";
	src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.eot?r=2");
	src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.eot?r=2#iefix") format("embedded-opentype"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.woff?r=2") format("woff"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.ttf?r=2") format("truetype"), url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.svg?r=2#fontcustom-dcs") format("svg");
	font-weight: normal;
	font-style: normal; }


@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "fontcustom-dcs";
		src: url("https://static.weboffice.uwa.edu.au/visualid/core-rebrand/fonts/fontcustom/fontcustom-dcs.svg?r=2#fontcustom-dcs") format("svg"); } }




$navGold: #E0B500;
$navBlue1: #1E2551;
$navBlue2: #214793;
$navBlue3: #1B356E;
$navBlue4: #214793;
$navBlueText: #27348b;
$navBlueDark: #161D4E;

$navBlue: #27348B;
$navWhite: #ffffff;
$navGrey: #ECECEC;
$navGreyText: #B2B2B2;
$navGrey2: #DDDDDD;
$navGrey3: #B2B2B2;
$navGrey4: #656565;
$navPurple: #773678;

$navPanel: #ffffff;
$navTabs: #171e51;
$navTabsText: #ffffff;
$navLabel: #B2B2B2;

$animSpeed: .3s;

@mixin menufull {
	@media screen and (max-width: 380px) {
		@content; } }

#uwamenu-navigation {
	display: none; }

/* #uwaMenu */

#uwaMenu {
	// resets first to better work on random pages such as those with the EVIL bootstrap.css
	a, div, span, em, img, dl, dt, dd, ol, ul, li, fieldset, form, input, button, label, menu, nav {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
		box-sizing: border-box; }
	a {
		@include link(0, 1, $navBlueText);
		transition: none; }
	ul, li {
		list-style: none;
		padding: 0;
		background: none; }
	li:before {
		display: none; }


	// now onto the menu
	background: rgba(0,0,0,0);
	//height: 0px
	overflow: hidden;
	position: fixed;
	right: 0px;
	top: 0px;
	bottom: 0px;
	transition: background $animSpeed ease;
	width: 0%;
	z-index: 9999;

	#uwaMenuInner {
		background: $navPanel;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		color: $navBlueText;
		cursor: default;
		font-size: 18px;
		min-height: 100%;
		position: absolute;
		right: 0px;
		top: 0px;
		bottom: 0px;
		overflow-y: scroll;
		transform: translate(100% ,0px);
		transition: transform $animSpeed ease;
		width: 380px;
		@include menufull() {
			width: 100%; } }


	&.uwaMenu-show {
		background: rgba(0,0,0,0.5);
		width: 100%;

		@include menufull() {
			background: transparent; }
		#uwaMenuInner {
			transform: translate(0px,0px);
			transition: transform $animSpeed ease; } }
	&.uwaMenu-hiding {
		transition: background $animSpeed 0s ease; //delyed hide
		background: rgba(0,0,0,0);
		width: 100%;
		#uwaMenuInner {
			transform: translate(380px,0px);
			transition: transform $animSpeed ease; } }
	&.uwaMenu-hidden {
		width: 0%; } }

body {
	&.uwaMenu-open {
		overflow: hidden;
		> *:not(#uwaMenu) {
			//transition: transform $animSpeed ease
			//filter: blur(5px)
			//transform: translateX(-200px)
			//transition: margin-left $animSpeed ease, margin-right $animSpeed ease
			//margin-left: -300px
 } }			//margin-right: 300px
	&.uwaMenu-closed {
		> *:not(#uwaMenu) {
			//transition: transform $animSpeed ease
			//transform: translateX(0px)
			//transition: margin-left $animSpeed ease, margin-right $animSpeed ease
			//margin-left: 0px
 } } }			//margin-right: 0px



#uwaMenu {
	font-family: $fontSans;
	font-weight: 400;
	#uwaMenuTabs {
		padding: 0px;
		font-size: 0px;
		background: $navTabs;
		li {
			display: inline-block;
			width: 50%; }
		&.local-disabled {
			li {
				width: 100%;
				&.local-tab {
					display: none; } } }

		a {
			display: block;
			font-family: $fontUWA;
			text-transform: uppercase;
			text-align: center;
			background: $navTabs;
			color: $navTabsText;
			font-size: 19px;
			letter-spacing: 0.05em;
			padding: 15px 0px;
			border: 2px solid $navTabsText;
			border-radius: 0px 10px 0 0;
			position: relative;
			.tab-corner {
				//background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='10' width='12'><polygon points='-2,-2 14,12 14,-2' /></svg>")
				width: 12px;
				height: 10px;
				position: absolute;
				top: -2px;
				right: -2px;
				overflow: hidden;
				polygon {
					fill: $navTabs;
					stroke: $navTabsText;
					stroke-width: 2; } }


			&.active {
				background: $navPanel;
				border-color: $navPanel;
				color: $navBlue;
				.tab-corner polygon {
					stroke: $navPanel; } } } }




	a.closeLink {
		background: $navTabs;
		color: $navTabsText !important;
		display: block;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		letter-spacing: 0.05em;
		padding: 15px 20px 15px 20px;
		text-transform: uppercase;
		i:before {
			color: $navGold;
			font-size: 12px;
			margin-right: 1em; } }

	#uwaMenuSearch {
		background: $navPanel;
		padding: 20px 10px 10px 10px;
		&:focus {
			outline: 0px; }
		fieldset {
			position: relative;
			box-shadow: 0px 3px 10px $navGrey; }//rgba(y, 0.5)
		#uwaMenuSearchWords {
			border: 1px solid $navGrey;
			background-color: white;
			box-shadow: none;
			color: $navGrey4;
			display: block;
			font-size: 18px;
			height: 50px;
			line-height: 30px;
			padding: 10px 100px 10px 10px;
			width: 100%;
			&:focus {
				color: #333333; } }
		button {
			background: $navWhite;
			height: 50px;
			line-height: 48px;
			position: absolute;
			text-align: center;
			top: 0px;
			width: 50px; }
		#uwaMenuSearchSubmit {
			background: $navGold;
			color: $navBlueText;
			right: 0px;
			cursor: pointer;
			border-top: 1px solid $navGrey;
			border-bottom: 1px solid $navGrey;
			border-right: 1px solid $navGrey; }

		#uwaMenuSearchClear {
			background: $navWhite;
			color: $navLabel;
			font-size: 12px;
			right: 50px;
			cursor: pointer;
			border-top: 1px solid $navGrey;
			border-bottom: 1px solid $navGrey; } }


	.uwaMenuPanel {
		background: $navPanel;
		display: none;
		padding: 10px;
		&.active {
			display: block; }
		&:focus {
			outline: none; }

		div.lineLabel {
			border-bottom: 1px solid $navLabel;
			clear: left;
			color: $navLabel;
			font-family: $fontUWA;
			font-size: 14px;
			letter-spacing: 0.1em;
			line-height: 19px;
			margin: 0px 10px 20px 10px;
			text-align: center;
			text-transform: uppercase;
			span {
				background-color: $navPanel;
				padding: 0px 10px;
				position: relative;
				top: 9px; } } }
	.siteTitle {
		text-align: left;
		padding: 0px 0px 20px 0px;
		vertical-align: top;
		@include clear;
		span {
			font-size: 14px;
			display: block;
			color: $navGreyText; }
		a {
			font-size: 20px;
			line-height: 24px;
			font-family: $fontUWA;
			display: block;
			margin-left: $size2; }
		i.fa {
			color: $navGold;
			line-height: 24px;
			float: left;
			width: $size2; } }


	ul.quickLinks {

		list-style: none;
		padding: 0px 0px;
		@include clear();
		li {
			box-sizing: border-box;
			float: left;
			margin: 0px;
			width: 33.33%;
			position: relative;
			height: 102px;
			a {
				color: $navWhite;
				background-color: $navBlue;
				display: block;
				font-size: 12px;
				line-height: 20px;
				padding: 15px 0px;
				position: relative;
				text-align: center;
				vertical-align: middle;
				text-transform: uppercase;
				z-index: 100;
				border: 1px solid $navWhite;
				box-sizing: border-box;

				i:before {
					text-decoration: none;
					color: $navGold;
					display: inline-block;
					font-size: 36px;
					line-height: 50px;
					text-align: center;
					vertical-align: middle;
					width: 100%; }
				&:link {
					text-decoration: none; }
				&:hover,
				&:focus {
					background-color: $navBlue;
					text-decoration: underline;
					position: absolute;
					top: -2px;
					left: -2px;
					right: -2px;
					bottom: -2px;
					box-shadow: 0px 2px 4px rgba(#000, 0.2);
					z-index: 101;
					font-size: 13px;
					i:before {
						line-height: 54px;
						font-size: 38px; } }
				&:active {
					background-color: $navBlueDark;
					i:before {
						color: white; } } } } }



	#siteNavTree {
		color: $navWhite;
		background-color: $navWhite;
		width: 100%;
		padding: 0px;
		position: relative;
		a {
			padding: 10px;
			display: block;
			font-size: 14px;

			&:focus {
				outline: 0px; }
			//&:visited
 }			//	color: $navPurple
		.has-child {
			position: relative; }
		a {
			margin-right: 40px; }
		&.loading:after {
			display: block;
			position: absolute;
			top: $size0;
			right: $size0;
			width: $size2;
			height: $size2;
			line-height: $size2;
			text-align: center;
			color: $colTextGrey;
			opacity: 0.5;
			@include psuedoLoadAnim; }

		li.open > .toggle:before {
			content: $fa-var-angle-up; }
		li.breadcrumb {
			> a {
				color: $navPurple;
				font-weight: 600;
				//&:before
				///content: $fa-var-bookmark-o
				///font-family: "FontAwesome"
				///display: none
				///font-size: 12px
				///width: 12px
				///position: absolute
 }				///left: -12px
			&.open > a {
				color: $navPurple; } }
		//> ul > li.breadcrumb > a:before
		///left: 2px
		li.thispage	> a {
			color: $navPurple;
			//&:before
			///display: inline-block
 }			///content: $fa-var-bookmark

		ul {
			padding: 0px;
			margin: 0px;
			list-style: none;
			li {
				position: relative;
				margin: 0;
				padding: 0; } }

		> ul > li {
			//top level items
			transition: background-color $animSpeed ease;
			background-color: $navWhite;
			border-top: 1px solid $navGrey;
			&:first-child {
				border-top: 0px; }
			> a {
				font-size: 18px;
				padding: 7px 0px 8px 0px;
				line-height: 21px;
				transition: color $animSpeed ease;
				color: $navBlue; }
			&.thispage, &.breadcrumb {
				> a {
					color: $navPurple; } }
			&.open {
				background-color: $navWhite;
				> a {
					color: $navBlueText; }
				&.thispage, &.breadcrumb {
					> a {
						color: $navPurple; } } }
			> .toggle {
				padding: 7px 0px 8px 0; }
			> ul {
				// level 2 items
				border-top: 1px solid $navGrey;
				background-color: $navWhite;
				padding: 0 0 0 0px;
				margin-left: 12px;
				> li:last-child {
					padding-bottom: 12px; } // smoother accordion
				> li ul {
					//level 3 and up
					> li:first-child {
						padding-top: 10px;
						border-top: 1px solid $navGrey; }
					> li:last-child {
						padding-bottom: 10px;
						border-bottom: 1px solid $navGrey; }
					> .toggle {
						padding-top: 15px; } } }

			ul {
				// all inner level items
				//transition: max-height 1s ease
				line-height: 16px;
				li {
					padding-left: 15px; }

				.toggle {
					padding: 5px 0px;
					line-height: 20px; }
				a {
					padding: 10px 0px;
					margin-left: -15px;
					font-size: 16px;
					letter-spacing: -0.015em; } } }
		.toggle {
			cursor: pointer;
			display: block;
			line-height: inherit;
			padding: 5px 0px;
			position: absolute;
			right: 0px;
			top: 0px;
			width: 40px;
			//transition: opacity 2s ease
			//opacity: 1
			//&.preshow
			//	transition: opacity 2s ease
			//	opacity: 0
			//cursor: ns-resize

			&:before {
				display: block;
				border-left: 1px solid $navGold;
				color: $navGold;
				text-align: center;
				line-height: inherit;
				vertical-align: center;
				content: $fa-var-angle-down;
				font-family: "FontAwesome"; }
			&:focus, &:hover {
				outline: 0;
				font-weight: bold; } }
		.open > .toggle {
			//cursor: ns-resize
			cursor: pointer; } }

	#globalNavTree {
		margin-bottom: 20px;
		> ul {
			color: $navBlueText;
			width: 100%;
			a {
				padding: 10px;
				display: block;
				letter-spacing: -0.015em;
				cursor: pointer;
				@include link(0, 1, $navBlueText);
				//&:visited
 }				//	color: $navPurple


			padding: 0px;
			margin: 0px;
			list-style: none;

			ul, li {
				list-style: none;
				position: relative;
				margin: 0;
				padding: 0; }
			> li {
				//level 1
				transition: background-color $animSpeed ease;
				background-color: $navWhite;
				border-top: 1px solid $navGrey;

				&:first-child {
					border-top: 0px; }
				> a {
					padding: 7px 40px 8px 0px;
					font-size: 18px;
					line-height: 21px;
					text-decoration: none;
					transition: color $animSpeed ease;
					//cursor: ns-resize
					cursor: pointer;
					//+link(0, 1, $navWhite)
					&:after {
						color: $navGold;
						content: $fa-var-angle-down;
						display: block;
						font-family: "FontAwesome";
						font-size: 18px;
						line-height: 21px;
						padding: 7px 0px 8px 0;
						position: absolute;
						right: 0px;
						text-align: center;
						top: 0px;
						vertical-align: center;
						width: 39px; } }
				&.open {
					background-color: $navWhite;
					> a {
						//cursor: ns-resize
						cursor: pointer;
						@include link(0, 1, $navBlueText);
						&:after {
							content: $fa-var-angle-up; } } }
				li {
					//level 2
					line-height: 16px;
					font-size: 16px;
					a {
						padding-left: 12px; } }
				ul {
					background-color: $navWhite;
					border-top: 1px solid $navGrey; } } } } }


